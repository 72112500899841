import React, { useEffect } from 'react'
import { graphql, navigate } from 'gatsby'
import { Seo } from '../components/base'
import { createPagePath, isBetweenDates, marketPath } from '../utils/'
import { LayoutBackground, BioHeaderHome } from '../components/shared'
import Modal from 'react-modal'
import { Layout } from '../components/layout'
import { getPageBuilderComponents } from '../components/base/PageBuilderComponents'
import { getLocaleMarket } from '../utils/getLocaleMarket'
import { getLocale, getLocaleMarketUrl } from '../utils/localeUtils'
import dayjs from 'dayjs'

Modal.setAppElement('#___gatsby')

const HomeIndex = ({ data, pageContext }) => {
  if (!data) return null
  const {
    hasCarouselBorder,
    marketCardButton,
    pageBuilder,
    previewImage,
    seo
  } = data.homePage

  const myMarket = getLocaleMarket()
  const languages = { pageContext }
  const groupNotifications = data.marketNotifications?.nodes
  const locale = getLocale()

  const getValidCarouselGroup = () => {
    let teaserGroup = []
    data.carouselGroup.nodes.map(item => {
      const isValid =
        item.validfrom && isBetweenDates(dayjs(), item.validfrom, item.validto)
      if (isValid) teaserGroup = item.teaserReference
    })
    return teaserGroup
  }

  useEffect(() => {
    const isBrowser = () => typeof window !== 'undefined'

    if (isBrowser()) {
      const isUrlToMarketPage =
        window.location.href.includes('http://localhost:8000/marktseite') ||
        window.location.href.includes('new.biomarkt.de/marktseite') ||
        window.location.href.includes('new.denns-biomarkt.at/marktseite')

      if (myMarket && isUrlToMarketPage) {
        navigate(
          getLocaleMarketUrl(
            locale,
            myMarket?.countryCode,
            marketPath(myMarket)
          )
        )
      }
    }
  }, [])

  return (
    <>
      <Layout isHomePage={true} isHeroTeaser={true} translations={languages}>
        <Seo
          title={seo?.metaTitle}
          description={seo?.metaDescription}
          image={seo?.ogImage?.asset?.url}
        />
        <BioHeaderHome
          heroTeaserLink={createPagePath({
            item: getValidCarouselGroup()
          })}
          isHomePage={true}
          teaserReference={getValidCarouselGroup()}
          previewImage={previewImage}
          marketCardButton={marketCardButton}
          market={myMarket}
          isFullWidth={true}
          button={getValidCarouselGroup()}
          groupNotifications={groupNotifications}
          hasCarouselBorder={hasCarouselBorder}
        />
        <LayoutBackground isHomePage={true}>
          {pageBuilder &&
            pageBuilder.map((item, index) => {
              const isHomePage = true
              return (
                <React.Fragment key={index}>
                  {getPageBuilderComponents(
                    item,
                    myMarket,
                    isHomePage,
                    {
                      ...data
                    },
                    pageContext
                  )}
                </React.Fragment>
              )
            })}
        </LayoutBackground>
      </Layout>
    </>
  )
}

export default HomeIndex

export const homeQuery = graphql`
  query (
    $dateToday: Date!
    $calendarWeek: Float!
    $nextWeek: Float!
    $language: String!
    $land: String!
  ) {
    sanityOfferPage {
      overviewHeadline
      overviewSubline
      overviewSublineMarket
      legalDisclaimer
    }
    marketNotifications: allSanityMarketNotification {
      nodes {
        ...GroupNotificationQuery
      }
    }
    carouselGroup: allSanityBioHeaderCarouselGroup(
      filter: {
        i18n_lang: { eq: $language }
        isNotAvailableInCountry: { ne: true }
      }
    ) {
      nodes {
        validfrom
        validto
        teaserReference {
          ...TeaserContentQuery
        }
      }
    }
    homePage: sanityHomePage(
      i18n_lang: { eq: $language }
      isNotAvailableInCountry: { ne: true }
    ) {
      hasCarouselBorder
      title
      subtitle
      seo {
        ...SeoQuery
      }
      i18n_lang
      marketCardButton {
        ...ButtonQuery
      }
      ...HomePageBuilderElements
    }
    sanityMarketOffers: allSanityOffer(
      filter: {
        marketHighlight: { eq: true }
        validfrom: { lte: $dateToday }
        validto: { gte: $dateToday }
        land: { eq: $land }
      }
      sort: {
        fields: [
          customCardColor___order
          articleGroup___productGroup___order
          title
          brand
        ]
      }
      limit: 6
    ) {
      nodes {
        ...OfferQuery
      }
    }

    bmvOnlyOffers: allSanityOffer(
      filter: {
        marketHighlight: { eq: true }
        validfrom: { lte: $dateToday }
        validto: { gte: $dateToday }
        land: { eq: $land }
        bmvOnly: { eq: true }
      }
      sort: {
        fields: [
          customCardColor___order
          articleGroup___productGroup___order
          title
          brand
        ]
      }
      limit: 3
    ) {
      nodes {
        ...OfferQuery
      }
      totalCount
    }
    sanityLunchPage {
      ...LunchPageQuery
    }
    allSanityLunchOffer(filter: { week: { eq: $calendarWeek } }) {
      nodes {
        ...LunchOfferQuery
      }
    }
    nextWeekOffer: allSanityLunchOffer(filter: { week: { eq: $nextWeek } }) {
      nodes {
        ...LunchOfferQuery
      }
    }
    allSanityBioMarketEvents {
      nodes {
        eventTeaser {
          ...EventQuery
        }
      }
    }
  }
`
